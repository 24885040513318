<template>
  <div
    v-if="moduleDashboardStatus === 'success'"
    class="module-dashboard-container overflow-y-hidden"
  >
    <div
      class="equipment-list-container ant-glass-background radius-0 ant-border-right"
    >
      <div class="equipment-search-container">
        <v-text-field
          v-model="equipmentSearch"
          clearable
          dense
          filled
          flat
          hide-details
          placeholder="Type here to search"
          prepend-inner-icon="mdi-magnify"
          rounded
        />
      </div>
      <div class="equipment-list">
        <div
          v-for="number in filteredEquipmentNumbers"
          :key="number"
          :class="{ active: selectedModule === number }"
          class="list-item"
          @click="viewModuleNumberDetail(number)"
        >
          {{ number }}
        </div>
      </div>
    </div>
    <div class="module-detail-container">
      <div class="viewer-container">
        <forge-viewer
          ref="forge-viewer"
          :ant-toolbar-options="{
            viewType: {
              display: true,
              enabled: false,
            },
            performanceMode: {
              display: true,
              enabled: true,
            },
            models: {
              display: false,
              enabled: false,
            },
            antTable: {
              display: false,
              enabled: false,
            },
            ghosting: {
              display: true,
              enabled: false,
            },
            modelTree: {
              display: false,
              enabled: false,
            },
            clearIsolation: {
              display: true,
              enabled: false,
            },
            objectProperties: {
              display: false,
              enabled: true,
            },
            sidebar: {
              display: false,
              enabled: false,
            },
          }"
          :client="moduleDashboardData.CFFA_FORGE_CLIENT.records[0]"
          :extension-options="[
            {
              name: 'DhmeModuleDashboardExtension',
              properties: undefined,
            },
          ]"
          :extensions="[
            'Autodesk.DocumentBrowser',
            'DhmeModuleDashboardExtension',
            'ToolbarExtension',
            'Autodesk.ViewCubeUi',
            'Autodesk.VisualClusters',
          ]"
          :models="moduleDashboardData.CFFA_FORGE_MODELS.records"
          @viewerReloading="authenticateForge"
        >
          <template #forge-overlay-container>
            <transition mode="out-in" name="fade">
              <div
                v-if="displayPhasesLegend"
                key="phases"
                class="forge-legend ant-glass-background"
              >
                <div class="legend-title">
                  <div class="flex-1">
                    {{ $t('modules.moduleDashboard.phases') }}
                  </div>
                  <v-tooltip left>
                    <template #activator="{ on, attrs }">
                      <v-btn
                        :color="colorPhasesToggle ? 'primary' : ''"
                        icon
                        v-bind="attrs"
                        @click="colorPhases()"
                        v-on="on"
                      >
                        <v-icon> mdi-format-color-highlight</v-icon>
                      </v-btn>
                    </template>
                    <span>{{ $t('modules.moduleDashboard.phases') }}</span>
                  </v-tooltip>
                </div>
                <ul class="legend-list flex">
                  <li
                    v-for="phase in phases"
                    :key="phase.name"
                    class="legend-item"
                  >
                    <div
                      :style="'background-color:' + phase.color"
                      class="legend-item-color"
                    />
                    <div class="legend-item-title">
                      {{ phase.name }}
                    </div>
                  </li>
                </ul>
              </div>
              <div
                v-if="displayModuleTypesLegend"
                key="modules"
                class="forge-legend ant-glass-background"
              >
                <div class="legend-title">
                  <div style="flex: 1">
                    {{ $t('modules.moduleDashboard.types') }}
                  </div>
                  <v-tooltip left>
                    <template #activator="{ on, attrs }">
                      <v-btn
                        :color="colorModuleTypesToggle ? 'primary' : ''"
                        icon
                        v-bind="attrs"
                        @click="colorModuleTypes()"
                        v-on="on"
                      >
                        <v-icon> mdi-format-color-highlight</v-icon>
                      </v-btn>
                    </template>
                    <span>{{ $t('modules.moduleDashboard.colorTypes') }}</span>
                  </v-tooltip>
                </div>
                <ul class="legend-list flex">
                  <li
                    v-for="type in distinctModules"
                    :key="type.type"
                    class="legend-item"
                  >
                    <div
                      :style="'background-color:' + type.color"
                      class="legend-item-color"
                    />
                    <div class="legend-item-title">
                      {{ type.type }}
                    </div>
                  </li>
                </ul>
              </div>
            </transition>
          </template>
          <template #ant-forge-toolbar-actions>
            <v-tooltip bottom>
              <template #activator="{ on, attrs }">
                <v-btn
                  :ripple="false"
                  class="mr-2"
                  icon
                  small
                  v-bind="attrs"
                  @click="displayQrReader = true"
                  v-on="on"
                >
                  <v-icon>mdi-qrcode-scan</v-icon>
                </v-btn>
              </template>
              <span>{{ $t('modules.moduleDashboard.scanQR') }}</span>
            </v-tooltip>
            <v-tooltip bottom>
              <template #activator="{ on, attrs }">
                <v-btn
                  :disabled="!selectedModule"
                  :ripple="false"
                  class="mr-2"
                  icon
                  small
                  v-bind="attrs"
                  @click="open2DDrawings"
                  v-on="on"
                >
                  <v-icon> mdi-video-2d</v-icon>
                </v-btn>
              </template>
              <span>{{ $t('modules.moduleDashboard.twoD') }}</span>
            </v-tooltip>
            <v-tooltip bottom>
              <template #activator="{ on, attrs }">
                <v-btn
                  :color="displayPhasesLegend ? 'primary' : ''"
                  :ripple="false"
                  class="mr-2"
                  icon
                  small
                  v-bind="attrs"
                  @click="togglePhasesLegend"
                  v-on="on"
                >
                  <v-icon> mdi-palette</v-icon>
                </v-btn>
              </template>
              <span>{{ $t('modules.moduleDashboard.togglePhases') }}</span>
            </v-tooltip>
            <v-tooltip bottom>
              <template #activator="{ on, attrs }">
                <v-btn
                  :color="displayModuleTypesLegend ? 'primary' : ''"
                  :ripple="false"
                  class="mr-2"
                  icon
                  small
                  v-bind="attrs"
                  @click="toggleModuleTypesLegend"
                  v-on="on"
                >
                  <v-icon> mdi-group</v-icon>
                </v-btn>
              </template>
              <span>{{ $t('modules.moduleDashboard.toggleModuleTypes') }}</span>
            </v-tooltip>
          </template>
        </forge-viewer>
      </div>
      <div
        v-if="selectedModule"
        class="ant-border-top ant-glass-background radius-0 d-flex pos-rel"
        style="height: 50%"
      >
        <div class="ant-border-right pa-2" style="width: 200px">
          <div class="d-flex align-center justify-center">
            {{ selectedModuleIndex }}
            <v-btn icon @click="clearDetailPanel">
              <v-icon>mdi-close</v-icon>
            </v-btn>
          </div>
          <div
            v-if="
              moduleDashboardModules.find(
                (record) => record.module_id === selectedModule
              )
            "
          >
            <div
              v-for="(key, index) of Object.keys(
                moduleDashboardModules.find(
                  (record) => record.module_id === selectedModule
                )
              ).filter((x) => x !== 'id')"
              :key="index"
              class="module-content"
            >
              <div class="content-key">{{ key }}:</div>
              <div class="content-value">
                {{
                  moduleDashboardModules.find(
                    (record) => record.module_id === selectedModuleIndex
                  )[key]
                }}
              </div>
            </div>
          </div>
        </div>
        <div class="overflow-auto flex-1">
          <v-data-table
            :headers="objectsForEquipmentNumberHeader"
            :items="objectsForEquipmentNumber"
            class="ant-glass-background"
            fixed-header
            height="100%"
            @click:row="findElementInViewer"
          >
            <template #item.element_code="{ item }">
              <td>
                {{ item.properties.JanSnel.ElementCode }}
                <v-icon small> mdi-pencil</v-icon>
              </td>
              <td>
                {{ item.properties.JanSnel.ElementCode }}
                <img
                  height="20px"
                  src="https://produuz.it/static/ico/apple-icon-57x57.png"
                  @click="openProduuzit"
                />
              </td>
            </template>
          </v-data-table>
        </div>
        <div
          class="pa-2 ant-border-left overflow-y-auto module-dashboard-apps-container"
        >
          <div
            v-for="app in redirectApps"
            :key="app"
            class="ant-glass-background ant-border module-dashboard-app d-flex align-center justify-center"
          >
            <a :href="app.url" target="_blank">
              <img
                :src="require('@/assets/DHME/' + app.image)"
                alt=""
                style=""
                width="75"
              />
            </a>
          </div>
        </div>
      </div>
    </div>

    <qr-scanner-dialog
      v-model="displayQrReader"
      title="Scan Element"
      @decoded="searchFromQrCode"
    />

    <v-dialog
      v-model="produuzDialog"
      max-width="1000px"
      @click:outside="closeProduuzDialog()"
    >
      <v-card>
        <v-card-title> Produuz.it</v-card-title>
        <v-card-text>
          <v-container>
            <v-row>
              <v-col cols="12" md="12" sm="6">
                <iframe
                  frameborder="0"
                  height="500px"
                  src="https://produuz.it/app/walls/331915ee-a098-4f49-84d2-d54ed9f64ec4"
                  width="100%"
                />
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>
        <v-card-actions>
          <v-spacer />
          <v-btn color="red" text @click="closeProduuzDialog()"> Cancel</v-btn>
          <v-btn color="primary" text @click="saveBucket()"> Save</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import AutodeskService from '../../services/forge/autodesk';
import '../../services/forge/extensions/dhme-module-dashboard-extension';
import '../../services/forge/extensions/toolbar-extension';
import { DHME_MODULE_DASHBOARD } from '@/modules/modules';
import QrScannerDialog from '@/components/Base/QrScannerDialog.vue';

export default {
  name: 'DHMEModelDetail',
  components: {
    QrScannerDialog,
    ForgeViewer: () => import('../../components/Modules/Forge/ForgeViewer'),
  },
  data: () => {
    return {
      headless: false,
      modelObjects: [],
      modelTypes: [],
      equipmentNumbers: [],
      equipmentSearch: null,
      selectedModule: undefined,
      objectsForEquipmentNumber: [],
      wvbForEquipmentNumber: undefined,
      produuzDialog: false,

      displayModuleTypesLegend: false,
      displayPhasesLegend: false,
      colorModuleTypesToggle: false,
      colorPhasesToggle: false,

      displayQrReader: false,

      redirectApps: [
        {
          url: 'https://www.commant.nl/',
          image: 'Commant.png',
        },
        {
          url: 'https://jansnel-my.sharepoint.com/personal/t_de_swaaf_jansnel_com/_layouts/15/onedrive.aspx?id=%2Fpersonal%2Ft%5Fde%5Fswaaf%5Fjansnel%5Fcom%2FDocuments%2FSMART%20Connected%20BIM%20%2D%20Fase%202%2FJS%20BIM%20Uitvoeringsmodel%20to%20Share',
          image: 'DaiwaQ.png',
        },
        { url: 'https://produuz.it/app/login', image: 'ProduuzIT.png' },
        { url: 'https://www.sap.com/', image: 'SAP.png' },
      ],
    };
  },
  computed: {
    ...mapGetters([
      'project',
      'moduleDashboardData',
      'moduleDashboardStatus',
      'moduleDashboardModules',
      'moduleDashboardPhases',
    ]),
    objectsForEquipmentNumberHeader() {
      const ignore = ['id', 'Equipmentnummer', 'Module type'];
      return this.objectsForEquipmentNumber
        .flatMap(Object.keys)
        .filter(unique)
        .filter((e) => !ignore.some((i) => i === e))
        .map((e) => ({ text: e, value: e }));
    },
    filteredEquipmentNumbers() {
      return !!this.equipmentSearch
        ? this.equipmentNumbers.filter((item) =>
            item.includes(this.equipmentSearch)
          )
        : this.equipmentNumbers;
    },
    distinctModules() {
      let result = [];
      this.modelTypes.forEach((type) => {
        result.push({ type: type, color: this.getRandomColor() });
      });
      return result;
    },
    phases() {
      if (this.moduleDashboardStatus === 'success') {
        return [...this.moduleDashboardPhases].sort((a, b) =>
          a.name.localeCompare(b.name)
        );
      } else {
        return [];
      }
    },
  },
  mounted() {
    this.$store
      .dispatch('fetchModelDashboardModuleData', {
        projectId: this.project.id,
        moduleId: this.project.modules.find(
          (module) => module.route === DHME_MODULE_DASHBOARD
        ).id,
        sessionId: this.$route.params.sessionId ?? null,
      })
      .then(() => this.authenticateForge());
  },
  methods: {
    open2DDrawings() {
      window.open(
        `https://collaborallnet.sharepoint.com/sites/Administratie/Gedeelde%20documenten/General/5-Delivery/JanSnel/pdf/${this.selectedModule}.pdf`,
        '_blank'
      );
    },
    searchFromQrCode(searchValue) {
      if (this.filteredEquipmentNumbers.find((x) => x === searchValue)) {
        this.viewModuleNumberDetail(searchValue);
        this.displayQrReader = false;
      }
    },
    clearDetailPanel() {
      let viewer = this.$refs['forge-viewer'];

      this.selectedModule = undefined;
      if (viewer) viewer.searchPhrase = undefined;

      this.$nextTick(() => {
        viewer?.viewerService?.resizeView();
        viewer?.clearSearch();
      });
    },
    toggleModuleTypesLegend() {
      this.displayModuleTypesLegend = !this.displayModuleTypesLegend;
      if (this.displayModuleTypesLegend) {
        this.displayPhasesLegend = false;
      }
    },
    togglePhasesLegend() {
      this.displayPhasesLegend = !this.displayPhasesLegend;
      if (this.displayPhasesLegend) {
        this.displayModuleTypesLegend = false;
      }
    },
    colorModuleTypes() {
      this.colorModuleTypesToggle = !this.colorModuleTypesToggle;

      if (this.colorModuleTypesToggle) {
        this.$refs[
          'forge-viewer'
        ]?.viewerService?.Viewer3D?.loadedExtensions?.DhmeModuleDashboardExtension?.colorModuleTypes(
          this.distinctModules
        );
      } else {
        this.$refs['forge-viewer'].viewerService.Viewer3D.clearThemingColors();
      }
    },
    colorPhases() {
      this.colorPhasesToggle = !this.colorPhasesToggle;

      if (this.colorPhasesToggle) {
        this.$refs[
          'forge-viewer'
        ]?.viewerService?.Viewer3D?.loadedExtensions?.DhmeModuleDashboardExtension?.setMDPhaseColors(
          this.moduleDashboardModules,
          this.phases
        );
      } else {
        this.$refs['forge-viewer'].viewerService.Viewer3D.clearThemingColors();
      }
    },
    getRandomColor() {
      let letters = '0123456789ABCDEF';
      let color = '#';
      for (let i = 0; i < 6; i++) {
        color += letters[Math.floor(Math.random() * 16)];
      }
      return color;
    },
    authenticateForge() {
      getForgeData(this.moduleDashboardData).then(
        ({ properties, modelTypes, equipmentNumbers }) => {
          const viewer = this.$refs['forge-viewer'];

          if (viewer)
            viewer.viewerService.Viewer3D.loadedExtensions.DhmeModuleDashboardExtension.options.properties =
              properties;

          this.modelObjects = properties;
          this.modelTypes = modelTypes;
          this.equipmentNumbers = equipmentNumbers;

          if (!!this.$route.query && this.$route.query.search) {
            this.viewModuleNumberDetail(this.$route.query.search);
          }

          setTimeout(
            () => viewer?.viewerService?.setViewTo('front top right'),
            10
          );
        }
      );
    },
    viewModuleNumberDetail(equipmentNumber) {
      const viewer = this.$refs['forge-viewer'];
      const objectsForEquipmentNumber =
        this.moduleDashboardData.CFFA_DHME_OBJECTS.records.filter(
          (o) => o.module_id === equipmentNumber
        );
      const wvbForEquipmentNumber = objectsForEquipmentNumber
        .map((o) => o.module_id)
        .filter(unique)
        .flatMap((e) =>
          this.moduleDashboardModules.filter((module) => module.module_id === e)
        )
        .reduce((a, b) => (a ? { ...a, ...b } : b), undefined);

      this.selectedModule = equipmentNumber;
      this.objectsForEquipmentNumber = objectsForEquipmentNumber;
      this.wvbForEquipmentNumber = wvbForEquipmentNumber;

      if (viewer) {
        viewer.searchInModel(this.selectedModule);
        viewer.searchPhrase = this.selectedModule;
      }

      this.$nextTick(() => viewer?.viewerService?.resizeView());
    },
    findElementInViewer(item) {
      this.$refs['forge-viewer'].viewerService.highlightElementInObject(
        item.name
      );
    },
    openProduuzit() {
      this.produuzDialog = true;
    },
    closeProduuzDialog() {
      this.produuzDialog = false;
    },
    saveBucket() {},
  },
};

async function getForgeData({ CFFA_FORGE_CLIENT, CFFA_FORGE_MODELS }) {
  const { client_id, secret, server_region } = CFFA_FORGE_CLIENT.records[0];
  const urn = CFFA_FORGE_MODELS.records.find((item) => item.enabled).urn;
  const access_token = await AutodeskService.fetchToken(
    client_id,
    secret,
    server_region
  );
  const guid = (await AutodeskService.getUrnMetadata(urn, access_token))[0]
    .guid;
  const properties = await AutodeskService.getGuidProperties(
    urn,
    guid,
    access_token
  );
  const janSnelProperties = properties
    .filter((item) => !!item.properties.JanSnel)
    .map((item) => item.properties.JanSnel);
  let modelTypes = janSnelProperties
    .filter((properties) => !!properties.ModuleType)
    .map((properties) => properties.ModuleType)
    .filter(unique);
  let equipmentNumbers = janSnelProperties
    .filter((properties) => !!properties.EquipmentNummer)
    .map((properties) => properties.EquipmentNummer)
    .filter(unique);

  return { properties, modelTypes, equipmentNumbers };
}

function unique(value, index, self) {
  return self.indexOf(value) === index;
}
</script>

<style lang="scss" scoped>
.module-dashboard-container {
  display: flex;
  height: 100%;

  .equipment-list-container {
    width: 250px;
    display: flex;
    flex-direction: column;

    .equipment-search-container {
      padding: 10px 12px;
    }

    .equipment-list {
      height: 100%;
      overflow-y: scroll;

      .list-item {
        width: 100%;
        text-align: center;
        padding: 5px 8px;
        border-bottom: solid 1px white;
        cursor: pointer;
        font-size: 14px;

        &:hover {
          background-color: white;
          border-left: solid 2px var(--v-primary-base);
        }

        &.active {
          background-color: white;
          border-left: solid 2px var(--v-primary-base);
        }
      }
    }
  }

  .module-detail-container {
    flex: 1;
    display: flex;
    flex-direction: column;

    .viewer-container {
      flex: 1;
      position: relative;

      .legend-toggles {
        position: absolute;
        right: 10px;
        bottom: 20px;
        z-index: 10;
      }

      .qr-code-scanner {
        position: absolute;
        left: 20px;
        bottom: 20px;
        z-index: 10;
      }

      .forge-legend {
        position: absolute;
        z-index: 2;
        max-height: 250px;
        top: 80px;
        left: 30px;
        padding: 10px 12px;
        overflow: hidden;
        display: flex;
        flex-direction: column;

        .legend-title {
          font-size: 16px;
          margin-bottom: 10px;
          display: flex;
          align-items: center;
        }

        .legend-list {
          overflow-y: scroll;
          list-style-type: none;
          padding: 0;

          .legend-item {
            width: 100%;
            display: flex;
            align-items: center;
            padding: 5px;
            transition: 200ms;
            font-size: 12px;

            .legend-item-title {
              flex: 1;
            }

            .legend-item-color {
              width: 24px;
              height: 24px;
              margin-right: 10px;
              border-radius: 24px;
            }
          }
        }
      }
    }

    .module-content {
      display: flex;
      font-size: 12px;

      .content-key {
        font-weight: bold;
        flex: 1;
      }

      .content-value {
        flex: 1;
        text-align: right;
        padding-left: 5px;
      }
    }

    .module-dashboard-apps-container {
      display: grid;
      grid-template-columns: repeat(1, 1fr);
      grid-auto-rows: 70px;
      grid-gap: 10px;

      .module-dashboard-app {
        cursor: pointer;
        transition: 200ms;
        border-left: solid 4px var(--v-primary-base);

        &:hover {
          background-color: lightgray;
        }
      }
    }
  }
}
</style>
